import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import '../src/app/components/i18/i18n';
import App from './app/App';
import registerServiceWorker from './registerServiceWorker';
import { ThemeProvider } from 'Context';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import '../src/app/utils/i18n/i18nInit';

const root = createRoot(document.getElementById('root'));
const baseUrl = document.getElementsByTagName('base')[0];
const rootElement = document.getElementById('root');
root.render(
  <ThemeProvider>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </ThemeProvider>
);

//reportWebVitals();
registerServiceWorker();
