import { PACKAGES_CODE } from "../actions/actions";

const INITIAL_STATE = {
  code: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PACKAGES_CODE: {
      return {
        ...state,
        code: action.code,
      };
    }
    default:
      return state;
  }
};
