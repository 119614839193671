import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const routes = [
  {
    element: <MatxLayout />,
    children: [
      ...materialRoutes,

      {
        path: '/dashboard/home',
        element: <Analytics />
        //auth: authRoles.admin
      }
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/signin', element: <JwtLogin /> },
  { path: '/home', element: <Navigate to="dashboard/home" /> },
  { path: '/', element: <Navigate to="signin" /> }
];

export default routes;
