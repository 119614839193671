import { ROBOT_TYPE } from "../actions/actions";

const INITIAL_STATE = {
  Type: [],
  state: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROBOT_TYPE: {
      return {
        ...state,
        Type: action.Type,
      };
    }
    default:
      return state;
  }
};
