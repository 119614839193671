import { DOWNLOAD_PROCESS } from '../actions/actions';

const INITIAL_STATE = {
  state: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_PROCESS: {
      return {
        ...state,
        state: action.state
      };
    }
    default:
      return state;
  }
};
