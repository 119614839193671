import { SPİNNER } from "../actions/actions";

const INITIAL_STATE = {
  Spinner: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SPİNNER: {
      return {
        ...state,
        Spinner: action.Spinner,
      };
    }
    default:
      return state;
  }
};
