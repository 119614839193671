import { ALL_FİLES, ALL_FİLES_TABLE_FILE_ID } from '../actions/actions';

const INITIAL_STATE = {
  Files: [],
  state: false,
  fileId: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_FİLES: {
      return {
        ...state,
        Folder: action.Folder,
        state: action.state
      };
    }
    case ALL_FİLES_TABLE_FILE_ID: {
      return {
        ...state,
        fileId: action.payload
      };
    }

    default:
      return state;
  }
};
