import { FOLDER_INFO } from '../actions/actions';

const INITIAL_STATE = {
  Folder: [],
  state: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FOLDER_INFO: {
      return {
        ...state,
        Folder: action.Folder
      };
    }
    default:
      return state;
  }
};
