import { ALL_MARKETTİNG } from '../actions/actions';

const INITIAL_STATE = {
  Files: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_MARKETTİNG: {
      return {
        ...state,
        Files: action.Files
      };
    }
    default:
      return state;
  }
};
