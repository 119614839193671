import { ALL_PACKAGES_TABLE, ALL_PACKAGES_ID_TABLE } from '../actions/actions';

const INITIAL_STATE = {
  Packages: [],
  packageName: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_PACKAGES_TABLE: {
      return {
        ...state,
        Packages: action.Packages
      };
    }
    case ALL_PACKAGES_ID_TABLE: {
      console.log('alooooooo', action);
      return {
        ...state,
        packageName: action.payload
      };
    }
    default:
      return state;
  }
};
