import { createContext, useReducer } from 'react';

export const themeContext = createContext();

const themeIsDark = () => {
  return (localStorage.getItem('theme') || 'light') === 'dark';
};

const setTheme = (isDark) => {
  localStorage.setItem('theme', isDark ? 'dark' : 'light');
};

const initialState = { darkMode: themeIsDark() };

const themeReducer = (state, action) => {
  switch (action.type) {
    case 'toggle':
      setTheme(!state.darkMode);
      return { darkMode: !state.darkMode };
    default:
      return state;
  }
};

export const ThemeProvider = (props) => {
  const [state, dispatch] = useReducer(themeReducer, initialState);
  return (
    <themeContext.Provider value={{ state, dispatch }}>{props.children}</themeContext.Provider>
  );
};
