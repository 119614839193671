import { UPDATES_INFO } from "../actions/actions";

const INITIAL_STATE = {
  updates_time: 0,
  message: null,
  state: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATES_INFO: {
      return {
        ...state,
        updates_time: action.updates_time,
        message: action.message,
        state: action.state,
      };
    }
    default:
      return state;
  }
};
