import { ROBOT_RUN_TIME_SOFTWARE } from '../actions/actions';

const INITIAL_STATE = {
  SoftwareInfo: {
    arosVersion: ""

  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROBOT_RUN_TIME_SOFTWARE: {
      return {
        ...state,
        Info: action.info
      };
    }
    default:
      return state;
  }
};
