import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducers from '../reducers/index';

export const history = createBrowserHistory();
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['RunTime']
};

const middlewares = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, reducers(history));

export default () => {
  let store = createStore(persistedReducer, applyMiddleware(...middlewares));

  let persistor = persistStore(store);
  return { store, persistor };
};
