import { ALL_PRODUCE_ROBOT, PRODUCED_ROBOTS_TABLE } from '../actions/actions';

const INITIAL_STATE = {
  Robots: [],
  robotId: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_PRODUCE_ROBOT: {
      return {
        ...state,
        Robots: action.Robots
      };
    }
    case PRODUCED_ROBOTS_TABLE: {
      return {
        ...state,
        robotId: action.payload
      };
    }

    default:
      return state;
  }
};
