import { USER_ROLE } from '../actions/actions';

const INITIAL_STATE = {
  Roles: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_ROLE: {
      return {
        ...state,
        Roles: action.Roles
      };
    }
    default:
      return state;
  }
};
