import { CssBaseline } from '@mui/material';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore, { history } from 'redux/store/configureStore';
import 'react-toastify/dist/ReactToastify.css';
import { MatxTheme } from './components';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { ConnectedRouter } from 'connected-react-router';
import { useContext } from 'react';
import { themeContext } from 'Context';
import './../config.jsx';

const TestComp = () => {
  const content = useRoutes(routes);
  const reduxData = useSelector((data) => data);
  console.log('reduxData: ', reduxData);
  return (
    <SettingsProvider>
      <MatxTheme>
        <CssBaseline />
        {content}
      </MatxTheme>
    </SettingsProvider>
  );
};

const App = () => {
  let { store, persistor } = configureStore();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <TestComp />
      </PersistGate>
    </Provider>
  );
};

export default App;
