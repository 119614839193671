import { SET_USERS, USER_INFO } from '../actions/actions';

const INITIAL_STATE = {
  User: [],
  role: null,
  token: null,
  state: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USERS: {
      return {
        ...state,
        User: { ...state.User, ...action.User }
      };
    }
    case USER_INFO: {
      return {
        ...state,
        User: action.User,
        token: action.token,
        role: action.role,
        state: action.state
      };
    }
    default:
      return state;
  }
};
