import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./english.json";
import translationTR from "./turkish.json";

const resources = {
  en: {
    translation: translationEN,
  },
  tr: {
    translation: translationTR,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng'
    },
    resources,
    lng: 'tr',
    fallbackLng: ["en", "tr"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
