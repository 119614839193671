import { Box, ButtonBase, Icon, styled } from '@mui/material';
import useSettings from 'app/hooks/useSettings';
import React, { Fragment, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Paragraph, Span } from '../Typography';
import MatxVerticalNavExpansionPanel from './MatxVerticalNavExpansionPanel';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { themeContext } from 'Context';
import { themeColors } from '../MatxTheme/themeColors';

const MatxVerticalNav = ({ items }) => {
  const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
    fontSize: '12px',
    marginTop: '20px',
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    display: mode === 'compact' && 'none',
    color: darkMode ? themeColors.slateDark2.palette.text.secondary : theme.palette.text.secondary
  }));

  const ExtAndIntCommon = {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px',
    height: 44,
    whiteSpace: 'pre',
    marginBottom: '8px',
    textDecoration: 'none',
    justifyContent: 'space-between',
    transition: 'all 150ms ease-in',
    '&:hover': { background: 'rgba(255, 255, 255, 0.08)' },
    '&.compactNavItem': {
      overflow: 'hidden',
      justifyContent: 'center !important'
    },
    '& .icon': {
      fontSize: '18px',
      paddingLeft: '16px',
      paddingRight: '16px',
      verticalAlign: 'middle'
    }
  };
  const ExternalLink = styled('a')(({ theme }) => ({
    ...ExtAndIntCommon,
    color: darkMode ? themeColors.slateDark2.palette.text.primary : theme.palette.text.primary
  }));

  const InternalLink = styled(Box)(({ theme }) => ({
    '& a': {
      ...ExtAndIntCommon,
      color: darkMode ? themeColors.slateDark2.palette.text.primary : theme.palette.text.primary
    },
    '& .navItemActive': {
      backgroundColor: 'rgba(255, 255, 255, 0.16)'
    }
  }));

  const StyledText = styled(Span)(({ mode }) => ({
    fontSize: '0.925rem',
    paddingLeft: '0.8rem',
    display: mode === 'compact' && 'none'
  }));

  const BulletIcon = styled('div')(({ theme }) => ({
    padding: '2px',
    marginLeft: '24px',
    marginRight: '8px',
    overflow: 'hidden',
    borderRadius: '300px',
    background: darkMode ? themeColors.slateDark2.palette.text.primary : theme.palette.text.primary
  }));

  const BadgeValue = styled('div')(() => ({
    padding: '1px 8px',
    overflow: 'hidden',
    borderRadius: '300px'
  }));

  const { settings } = useSettings();
  const { mode } = settings.layout1Settings.leftSidebar;
  const navigate = useNavigate();
  const User = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  const SideBarComp = ({ user }) => {
    if (user === 'Tester' || user === 'Admin' || user === 'Developer') {
      navigate('/dashboard/home');
    } else if (user === 'Tester' || user === 'Admin') {
      navigate('/signin');
    } else {
      navigate('/signin');
    }
  };

  // const SideBarComponent = ({ user }) => {
  //   if (user === 'Tester' || user === 'Admin' || user === 'Developer') {
  //     navigate('/dashboard/home');
  //   } else {
  //     navigate('/signin');
  //   }
  // };

  // <div>
  //   {User === null ? navigate('/signin') : <SideBarComponent user={User.role}></SideBarComponent>}
  // </div>;

  const renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.user === User.role) {
        if (item.type === 'label')
          return (
            <>
              <ListLabel key={index} mode={mode} className="sidenavHoverShow">
                {item.label}
              </ListLabel>
              <hr />
            </>
          );

        if (item.children) {
          return (
            <NavLink style={{ color: 'transparent' }} to={item.path}>
              <MatxVerticalNavExpansionPanel mode={mode} item={item} key={index}>
                {renderLevels(item.children)}
              </MatxVerticalNavExpansionPanel>
            </NavLink>
          );
        } else if (item.type === 'extLink') {
          return (
            <ExternalLink
              key={index}
              href={item.path}
              className={`${mode === 'compact' && 'compactNavItem'}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
                {(() => {
                  if (item.icon) {
                    return <Icon className="icon">{item.icon}</Icon>;
                  } else {
                    return <Span className="item-icon icon-text">{item.iconText}</Span>;
                  }
                })()}
                <StyledText mode={mode} className="sidenavHoverShow">
                  {item.name}
                </StyledText>
                <Box mx="auto"></Box>
                {item.badge && <BadgeValue>{item.badge.value}</BadgeValue>}
              </ButtonBase>
            </ExternalLink>
          );
        } else {
          return (
            <InternalLink key={index}>
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? `navItemActive ${mode === 'compact' && 'compactNavItem'}`
                    : `${mode === 'compact' && 'compactNavItem'}`
                }
              >
                <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
                  {item?.icon ? (
                    <Icon className="icon" sx={{ width: 36 }}>
                      {item.icon}
                    </Icon>
                  ) : (
                    <Fragment>
                      <BulletIcon
                        className={`nav-bullet`}
                        sx={{ display: mode === 'compact' && 'none' }}
                      />
                      <Box
                        className="nav-bullet-text"
                        sx={{
                          ml: '20px',
                          fontSize: '11px',
                          display: mode !== 'compact' && 'none'
                        }}
                      >
                        {item.iconText}
                      </Box>
                    </Fragment>
                  )}
                  <StyledText mode={mode} className="sidenavHoverShow">
                    {item.name}
                  </StyledText>

                  <Box mx="auto" />

                  {item.badge && (
                    <BadgeValue className="sidenavHoverShow">{item.badge.value}</BadgeValue>
                  )}
                </ButtonBase>
              </NavLink>
            </InternalLink>
          );
        }
      }
    });
  };

  return <div className="navigation">{renderLevels(items)}</div>;
};

export default React.memo(MatxVerticalNav);
