import { CssBaseline, ThemeProvider } from '@mui/material';
import { themeContext } from 'Context';
import useSettings from 'app/hooks/useSettings';
import { useContext } from 'react';
import { themeColors } from './themeColors';

const MatxTheme = ({ children }) => {
  const { settings } = useSettings();
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  let activeTheme = { ...settings.themes[settings.activeTheme] };

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MatxTheme;
