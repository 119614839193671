import { FİLE_DESCRİPTİON, FİLE_DESCRİPTİON_DATA } from '../actions/actions';

const INITIAL_STATE = {
  FileDescription: [],
  Files: [],
  state: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FİLE_DESCRİPTİON: {
      console.log('action.FileDescription: ', action.FileDescription);
      return {
        ...state,
        FileDescription: action.FileDescription
      };
    }
    case FİLE_DESCRİPTİON_DATA: {
      return {
        ...state,
        Files: action.files
      };
    }
    default:
      return state;
  }
};
