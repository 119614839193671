import { AROS_TESTER } from '../actions/actions';

const INITIAL_STATE = {
  ArosTree: [],
  ArosChecked: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AROS_TESTER: {
      return {
        ...state,
        ArosTree: action.ArosTree,
        ArosChecked: action.ArosChecked
      };
    }
    default:
      return state;
  }
};
